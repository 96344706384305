import React, { useEffect, useRef } from 'react';
import { Row, Col } from '@thd-olt-component-react/core-ui';
import { func, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import * as analytics from '../analytics/analytics';
import '../../AugmentedReality.style.scss';

const ARContent = ({ assetLink, channel, forwardedRef, onClick }) => {
  const arClassName = classNames('augmented-reality__wrapper', {
    'augmented-reality__wrapper--desktop': channel !== 'mobile'
  });

  const handleOnClick = (clickEvent) => {
    analytics.track(clickEvent);
    if (onClick) {
      if (clickEvent) {
        clickEvent.preventDefault();
      }
      onClick(assetLink);
    }
  };

  const handleMediaGalleryClick = ({ output }) => {
    const {
      component,
      target,
    } = output;

    if (target === 'ar' && component === 'thumbnails') {
      handleOnClick();
    }
  };
  useEffect(() => {

    window.LIFE_CYCLE_EVENT_BUS.on('media-gallery.click', handleMediaGalleryClick);
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('media-gallery.click', handleMediaGalleryClick);
    };
  }, []);

  const buttonLink = (
    <button ref={forwardedRef} className="augmented-reality__button" onClick={handleOnClick} type="button">
      <span className="augmented-reality__image">
        {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
        <img src="https://assets.homedepot-static.com/images/v1/AR-cube-darkgrey.svg" alt="" />
      </span>
      <span className="augmented-reality__button-prompt">
        <span className="augmented-reality__wrapper--view">See it in your home</span>
        <span> with 3D Augmented Reality</span>
      </span>
      <span className="augmented-reality__wrap--arrow" />

    </button>
  );

  return onClick ? buttonLink : (
    <a
      href={assetLink}
      rel="ar"
      className="augmented-reality__link"
      onClick={handleOnClick}
    >
      {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
      <img src="" alt="" aria-hidden="true" />
      <Row className={arClassName}>
        <Col>
          <span className="augmented-reality__image">
            {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
            <img src="https://assets.homedepot-static.com/images/v1/AR-cube-darkgrey.svg" alt="" />
          </span>
        </Col>
        <Col className="augmented-reality__text">
          <span>
            <span className="augmented-reality__wrapper--view">See it in your home</span>
            <div> with 3D Augmented Reality</div>
          </span>
        </Col>
        <Col className="augmented-reality__wrap">
          <span className="augmented-reality__wrap--arrow" />
        </Col>
      </Row>
    </a>
  );
};

ARContent.propTypes = {
  assetLink: string,
  channel: oneOf(['desktop', 'mobile']),
  forwardedRef: shape({}),
  onClick: func
};

ARContent.defaultProps = {
  assetLink: '',
  channel: 'desktop',
  forwardedRef: null,
  onClick: null
};

export { ARContent };

export const avataarAugmentedRealityIdsTnT = [
  '203008658', '206806288', '206806292', '206806293', '303566482', '304580723', '304602853', '304602858', '319857211',
  '305901526', '305993886', '307337023', '307337039', '308631043', '308736997', '309122286', '309568118', '310031821',
  '311041653', '312106940', '312447366', '312447400', '312899843', '313364546', '316744779', '318304560', '319445745',
  '319511547', '313364545', '203008386', '307337012', '308631035', '310031805', '312930135', '313703135', '206097910',
  '314611555', '314611558', '315396312', '318055396', '318304556', '318304557', '321898042', '319446772', '319535353',
  '304602851', '304602855', '305976840', '305990389', '307864717', '312447378', '313703138', '319757306', '319857128',
  '314611556', '314729408', '315100548', '315123999', '315125500', '315125680', '315396317', '315416018', '321087975',
  '315966208', '316744786', '316744788', '316744790', '316967939', '316967951', '316999600', '317000001', '317102240',
  '322143403', '318282777', '311041660', '310031823', '203184596', '318806668', '315396313', '304602860', '319670674',
  '319534774', '304602856', '319670761', '321913281', '311738471', '323302719', '318304559', '319535362', '318304558',
  '312275136', '312284218', '312285981', '312439629', '312439700', '312440061', '312440202', '303516040', '320119641',
  '312440282', '312440605', '312441660', '312563953', '312903023', '313001380', '312751609', '312758394', '318100023',
  '313803342', '314024386', '314103811', '312285857', '312286019', '314755122', '320026646', '303516039', '321774731',
  '321887244', '313846237', '318268649', '320326878', '315868139', '316091585', '316393269', '319923566', '312440135',
  '313550124', '314103818', '314145556', '314312330', '317914041', '317989076', '318069436', '315879068', '314744262',
  '315750223', '315868404', '315949991', '315972136', '317013047', '319615744', '319615868', '318268534', '317436989',
  '317445728', '317821963', '317843042', '318100083', '319615947', '315868024', '319503107', '320119258', '320326726',
  '320326746', '320484893', '320690384', '321253238', '206355460', '316391992', '313846213', '317436954', '320119266',
  '317989080', '312450168', '320020843', '319502657', '319696043', '320326840', '320612278', '312284270', '312285899',
  '315423281', '317013048', '315879054', '321774705', '314312141', '319615861', '319503087', '315868429', '316758363',
  '317436804', '317445512', '317450098', '317612685', '317651326', '317651351', '317651467', '317914032', '320587744',
  '317842096', '319615951', '320326920', '321151188', '320061862', '322117741', '314024432', '319923732', '315845275',
  '313489925', '315423162'
];
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { func, string } from 'prop-types';
import { Loader } from '@thd-olt-component-react/core-ui';
import { Link } from '../Link/Link.component';
import './ARInstructions.style.scss';

export const ARInstructions = ({ loadedAsset, onViewerClick }) => {

  const [goingToView, setGoingToView] = useState(false);
  useEffect(() => {
    if (goingToView) {
      onViewerClick();
      setGoingToView(false);
    }
  }, [goingToView]);

  const linkClasses = classNames('ar-instructions__ready-link', {
    'ar-instructions__ready-link--disabled': goingToView
  });

  return (
    <div className="ar-instructions">
      {loadedAsset
        // eslint-disable-next-line @mizdra/layout-shift/require-size-attributes
        ? <img src="https://assets.homedepot-static.com/images/v1/alert-check-green.svg" alt="" />
        : <Loader active />}
      <div className="ar-instructions__drawer-header">
        See It in Your Space is {loadedAsset ? 'Ready' : 'Loading'}
      </div>
      <p>We&apos;ll place the item in your space using your camera.</p>
      <p>
        To get the item to appear, point your phone towards the floor
        and move it around slowly.
        Avoid moving past furniture or other items that could
        prevent the viewer from finding an empty spot.
      </p>
      <p>Once you see the item, tap and drag it to position it in your space.
        Pinch to make it larger or smaller.
      </p>
      {loadedAsset && (
        <Link
          href={loadedAsset}
          className={linkClasses}
          onClick={() => setGoingToView(true)}
        >
          <span>Go to Viewer</span>
        </Link>
      )}
    </div>
  );
};

ARInstructions.propTypes = {
  loadedAsset: string,
  onViewerClick: func.isRequired
};

ARInstructions.defaultProps = {
  loadedAsset: null
};

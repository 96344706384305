import React, { useContext } from 'react';
import { string } from 'prop-types';
import { useConfigService, ExperienceContext } from '@thd-nucleus/experience-context';
import { Button } from '@thd-olt-component-react/core-ui';
import { avataarAugmentedRealityIdsTnT } from './avataar-skus';
import './Avataar.style.scss';

export const Avataar = ({ itemId }) => {
  const experienceContext = useContext(ExperienceContext);
  const fsAvataar = useConfigService('fs:isAvataarEnable');
  const { channel } = experienceContext;
  const isDesktop = channel === 'desktop';
  const containAvataarId = (id) => {
    if (id) {
      return avataarAugmentedRealityIdsTnT.indexOf(id) > -1;
    }
    return false;
  };

  const showAvataarAR = () => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('media-gallery.avataar.click', { showAvataar: true });
    }
  };

  if (!fsAvataar || !itemId || !containAvataarId(itemId)) {
    return null;
  }

  return (
    <Button
      className={`${isDesktop ? 'avataar-btn' : ''}`}
      outline
      dark
      onClick={showAvataarAR}
    >
      <div>View in 3D Augmented Reality</div>
    </Button>
  );
};

Avataar.displayName = 'Avataar';

Avataar.propTypes = {
  itemId: string.isRequired,
};
